import { useInterpret } from "@xstate/react";
import React, { createContext } from "react";
import { authMachine, AuthMachineInterpreter } from "../machines/authMachine";
import { IAuthImpl } from "../typings";

export const AuthContext = createContext({} as AuthMachineInterpreter);

/**
 * Enables calling the `useAuth()` hook from within your React tree.
 *
 * This is used in the `<Layout />` component.
 *
 * The `IAuthImpl` to use must be passed in.
 */
export const AuthProvider: React.FC<{
  impl: IAuthImpl;
  children: React.ReactNode;
}> = ({ impl, children }) => {
  const authService = useInterpret(authMachine, impl);

  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
};
