import config from "../../clhbid-config";

export default function generateIndexName(postfix: string): string {
  if (config.MOCK_AUCTION_DATA === "true") return `mock-auctions-${postfix}`;

  const url = new URL(config.AUCTION_ENGINE_URL as string);
  const prefix = url.hostname.split(".").join("-");

  return `${prefix}-${postfix}`;
}
