import React from "react";
import algoliasearch from "algoliasearch/lite";
import { history } from "instantsearch.js/es/lib/routers";
import { InstantSearch } from "react-instantsearch-hooks-web";
import qs from "qs";
import { navigate } from "gatsby";

import config from "../../clhbid-config";
import generateIndexName from "../helpers/generate-index-name";

const searchClient = algoliasearch(
  config.ALGOLIA_APP_ID,
  config.ALGOLIA_SEARCH_KEY
);

const indexName = generateIndexName("pages");

const createURL = ({ routeState }) => {
  const queryString = qs.stringify({
    query: encodeURIComponent(routeState[indexName].query),
  });

  return `/search?${queryString}`;
};

const parseURL = ({ location }) => {
  const { query = "" } = qs.parse(location.search.slice(1));

  return {
    [indexName]: {
      query: decodeURIComponent(String(query)),
    },
  };
};

const onStateChange = ({ uiState, setUiState }) => {
  setUiState(uiState);
  navigate(createURL({ routeState: uiState }), {});
};

const SearchContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      onStateChange={onStateChange}
      routing={{
        router: history({ createURL, parseURL }),
      }}
    >
      {children}
    </InstantSearch>
  );
};

export default SearchContext;
