import React from "react";
import { Link, graphql } from "gatsby";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import Button from "../../../Button";
import TestimonialCard from "../../../TestimonialCard";

const TestimonialPanel: React.FC<{
  title: string;
  testimonials: ReadonlyArray<Queries.TestimonialPanelFragment>;
  links: Array<{ title: string; path: string }>;
  button: { title: string; path: string };
}> = ({ title, testimonials, button, links }) => (
  <div className="grid grid-cols-1 gap-8 mb-6 lg:gap-0 lg:mb-0 lg:grid-cols-[280px,560px]">
    <div className="flex flex-col lg:px-8 lg:border-r lg:border-gray-300">
      <h3 className="hidden text-xl leading-6 font-bold mb-6 lg:block">
        {title}
      </h3>
      {links.map((link) => (
        <div key={link.title}>
          <Link
            key={link.title}
            to={link.path}
            className="block w-fit font-[18px] leading-6 text-gray-700 mb-4 hover:underline"
          >
            {link.title}
          </Link>
        </div>
      ))}
      <Button
        external
        size="small"
        href={button.path}
        className="w-full mt-4 lg:mt-auto"
      >
        {button.title}
      </Button>
    </div>
    <div className="relative pb-10 lg:pb-0 lg:flex lg:items-center lg:px-8">
      <CarouselProvider
        infinite
        interval={20000}
        isPlaying={true}
        dragEnabled={false}
        touchEnabled={false}
        naturalSlideHeight={1}
        naturalSlideWidth={1.5}
        isIntrinsicHeight={true}
        totalSlides={testimonials.length}
        className="w-full"
      >
        <ButtonBack className="absolute z-10 left-[calc(50%-32px)] bottom-0 lg:left-2 lg:bottom-auto lg:top-[calc(50%-12px)]">
          <MdChevronLeft className="w-6 h-6 rounded-full transition-colors hover:cursor-pointer hover:bg-gray-300" />
        </ButtonBack>
        <Slider classNameTrayWrap="py-2">
          {testimonials.map((testimonial, i) => (
            <Slide
              key={testimonial.name}
              index={i}
              className="px-2"
              innerClassName="flex items-center"
            >
              <TestimonialCard testimonial={testimonial} />
            </Slide>
          ))}
        </Slider>
        <ButtonNext className="absolute z-10 right-[calc(50%-32px)] bottom-0 lg:right-2 lg:bottom-auto lg:top-[calc(50%-12px)]">
          <MdChevronRight className="w-6 h-6 rounded-full transition-colors hover:cursor-pointer hover:bg-gray-300" />
        </ButtonNext>
      </CarouselProvider>
    </div>
  </div>
);

export const TestimonialPanelFragment = graphql`
  fragment TestimonialPanel on TestimonialsYaml {
    ...TestimonialCard
  }
`;

export default TestimonialPanel;
