import { useEffect } from "react";

const WavesEffect: React.FC = () => {
  useEffect(() => {
    const wavesConfig = {
      duration: 500,
      delay: 200,
    };

    window.Waves.attach(".btn", ["waves-ripple"]);
    window.Waves.attach(".wave-submenu", ["waves-ripple"]);
    window.Waves.attach(".wave-sidebar", ["waves-ripple"]);
    window.Waves.init(wavesConfig);
  }, []);

  return null;
};

export default WavesEffect;
