import {
  createMachine,
  assign,
  StateFrom,
  ContextFrom,
  InterpreterFrom,
  ActorRefFrom,
} from "xstate";
import { log } from "xstate/lib/actions";
import { AuthUser } from "../typings";

/**
 * An auth machine is a state machine that manages the authentication of a user.
 *
 * Initialy it begins in an `initializing` state where the user is restored
 * if possible. Once initialization completes, the user is either authenticated
 * or not.
 *
 * Unathenticated users can authenticate, or register a new account.
 *
 * Authenticated users can unauthenticate.
 *
 * The actual authenticate, unauthentication, and registration logic is handled
 * by services which must be provided when the machine is interpretted.
 *
 * If an error occurs at any time the machine enters an error state. We're not
 * anticipating users ever reach this state so it's recommended for now that
 * we log as many details as possible, trigger an alert in Sentry, and display an
 * error message to the user asking them to reload the page and contact
 * CLHbid.com if the error persists.
 */
export const authMachine = createMachine(
  {
    tsTypes: {} as import("./authMachine.typegen").Typegen0,
    predictableActionArguments: true,
    context: { user: null },
    schema: {
      context: {} as { user: AuthUser | null },
      events: {} as
        | { type: "UNAUTHENTICATE" }
        | { type: "AUTHENTICATE" }
        | { type: "REGISTER" }
        | { type: "EDIT_ACCOUNT" },
      services: {} as {
        initialize: {
          data: AuthUser | null;
        };
        authenticate: {
          data: AuthUser;
        };
        unauthenticate: {
          data: null;
        };
        register: {
          data: AuthUser;
        };
        editAccount: {
          data: null;
        };
      },
    },
    id: "auth",
    initial: "initializing",
    states: {
      initializing: {
        invoke: {
          src: "initialize",
          id: "initialize",
          onDone: [
            {
              actions: "storeUser",
              target: "validating",
            },
          ],
          onError: [
            {
              target: "error",
            },
          ],
        },
      },
      validating: {
        always: [
          {
            cond: "isValid",
            target: "authenticated",
          },
          {
            target: "unauthenticated",
          },
        ],
      },
      authenticated: {
        entry: "logUser",
        on: {
          UNAUTHENTICATE: {
            target: "unauthenticating",
          },
        },
        initial: "normal",
        states: {
          normal: {
            on: {
              EDIT_ACCOUNT: "editing_account",
            },
          },
          editing_account: {
            invoke: {
              src: "editAccount",
              onDone: { target: "normal" },
              onError: { target: "#error" },
            },
          },
        },
      },
      unauthenticated: {
        entry: "clearUser",
        on: {
          AUTHENTICATE: {
            target: "authenticating",
          },
          REGISTER: {
            target: "registering",
          },
        },
      },
      authenticating: {
        invoke: {
          src: "authenticate",
          onDone: [
            {
              actions: "storeUser",
              target: "validating",
            },
          ],
          onError: [
            {
              target: "error",
            },
          ],
        },
      },
      unauthenticating: {
        invoke: {
          src: "unauthenticate",
          onDone: [
            {
              actions: "clearUser",
              target: "validating",
            },
          ],
          onError: [
            {
              target: "error",
            },
          ],
        },
      },
      registering: {
        invoke: {
          src: "register",
          onDone: [
            {
              actions: "storeUser",
              target: "validating",
            },
          ],
          onError: [
            {
              target: "error",
            },
          ],
        },
      },
      error: {
        id: "error",
        entry: ["handleError", "clearUser"],
        on: {
          AUTHENTICATE: {
            target: "authenticating",
          },
        },
      },
    },
  },
  {
    actions: {
      logUser: log((ctx) => `User "${ctx?.user?.UserName}" is authenticated`),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      clearUser: assign({ user: (_ctx, _evt) => null }),
      storeUser: assign({
        user: (ctx, evt) => ("data" in evt ? evt.data : null),
      }),
    },
    guards: {
      isValid: (ctx) => ctx.user !== null,
    },
  }
);

export type AuthMachineState = StateFrom<typeof authMachine>;
export type AuthMachineContext = ContextFrom<typeof authMachine>;
export type AuthMachineInterpreter = InterpreterFrom<typeof authMachine>;
export type AuthMachineActorRef = ActorRefFrom<typeof authMachine>;

// HACK: When xstate typegen exposes an official way to access the state type, use that (see https://github.com/statelyai/xstate-tools/issues/169)
export type AuthMachineStates =
  import("./authMachine.typegen").Typegen0["matchesStates"];
