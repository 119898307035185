import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Link } from "gatsby";
import useRouteChange from "../../hooks/use-route-change";

import Banner from "./Banner";
import MainMenu from "./MainMenu";
import UserMenu from "./UserMenu";

import logo from "../../images/clhbid-logo.png";

const Header: React.FC = () => {
  const header = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState<string | undefined>(undefined);

  useEffect(() => {
    // prevent scrollbar on body when mobile menu is openmenu
    document.body.classList.toggle("overflow-hidden", Boolean(openMenu));
    document.body.classList.toggle("lg:overflow-auto", Boolean(openMenu));
  }, [openMenu]);

  const setBodyHeight = () => {
    if (header.current) {
      document.body.style.marginTop = `${header.current.clientHeight}px`;
      document.documentElement.style.scrollPaddingTop = `${header.current.clientHeight}px`;
    }
  };

  useLayoutEffect(() => {
    setBodyHeight();

    window.addEventListener("resize", setBodyHeight);

    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  });

  useRouteChange(() => {
    setOpenMenu(undefined);
  });

  const createSetOpenMenu = (menu: "user-menu" | "main-menu") => {
    return (value: boolean) => {
      if (value === true) {
        setOpenMenu(menu);
      } else if (value === false && openMenu === menu) {
        setOpenMenu(undefined);
      }
    };
  };

  return (
    <header ref={header} className="fixed z-50 top-0 left-0 right-0">
      <div className="absolute -z-10 inset-0 bg-white shadow-md" />
      <Banner />
      <nav className="grid w-full mx-auto px-4 py-[10px] grid-cols-[1fr_auto_auto] lg:leading-none lg:py-6 lg:grid-cols-[auto_1fr_auto] xl:max-w-[1200px]">
        <div className="relative">
          <Link to="/" className="block lg:mr-12">
            <img
              src={logo}
              alt="CLHbid.com logo"
              className="h-10 py-1 lg:h-[44px]"
            />
          </Link>
        </div>
        <MainMenu
          open={openMenu === "main-menu"}
          setOpen={createSetOpenMenu("main-menu")}
        />
        <UserMenu
          open={openMenu === "user-menu"}
          setOpen={createSetOpenMenu("user-menu")}
        />
      </nav>
    </header>
  );
};

export default Header;
