import React from "react";
import { DateTime } from "luxon";

const DEFAULT_FORMAT_OPTIONS = DateTime.DATETIME_FULL;

const LocalDate: React.FC<{
  className?: string;
  dateAsISOString: string;
  /**
   * For format options see the tc39 spec at https://tc39.es/ecma402/#table-datetimeformat-components
   * For luxon formatting options see the luxon docs at https://moment.github.io/luxon/#/formatting?id=tolocalestring-strings-for-humans
   */
  formatOptions?: Intl.DateTimeFormatOptions;
}> = ({
  className,
  dateAsISOString,
  formatOptions = DEFAULT_FORMAT_OPTIONS,
}) => {
  const localFormattedDate =
    DateTime.fromISO(dateAsISOString).toLocaleString(formatOptions);
  return <span className={className}>{localFormattedDate}</span>;
};

export default LocalDate;
