// This calculation determines if the menu will overflow off the screen if
// set to the default position, and if so moves it to the left so that it
// perfectly lines up with the edge of the screen
export const calculateOffset = (
  window: { innerWidth: number },
  button: {
    getBoundingClientRect: () => { x: number; y: number };
    clientHeight: number;
  },
  container: { scrollWidth: number }
): { left: number; top: number } => {
  const left =
    // If the width of the window allows for the left edge of the dropdown to line up
    // with the left edge of it's button
    window.innerWidth > button.getBoundingClientRect().x + container.scrollWidth
      ? // Set the left position to line up the left edges
        button.getBoundingClientRect().x
      : // Otherwise set the dropdown as close to the left edge as possible without overflowing
        window.innerWidth - container.scrollWidth;

  // Set the top of the dropdown to be directly below the bottom edge of the button
  const top = button.getBoundingClientRect().y + button.clientHeight;

  return {
    left,
    top,
  };
};

// This calculation determines what height to force the wrapper to,
// either its normal height if open (allows for a transition in CSS),
// or 0 if closed (hides the content).
export const calculateHeight = (
  open: boolean,
  containerScrollHeight: number
): number => {
  return (open && containerScrollHeight) || 0;
};
