import React from "react";

import useAuctions from "../hooks/use-auctions";

import Button from "./Button";

const AuctionBanner: React.FC = () => {
  const { auctions, isError, isFetching } = useAuctions();

  const liveAuctions = auctions?.active_auctions.filter(
    (auction) => auction.isLive
  );

  if ((!auctions && isFetching) || isError || liveAuctions.length === 0) {
    return null;
  }

  return (
    <div className="fixed z-[49] flex items-center space-x-8 px-4 py-3 bg-gray-800 bottom-0 right-0 rounded-tl-xl shadow-[0_0_8px_0_rgba(0,0,0,0.4)] w-full max-w-full sm:w-fit">
      <div className="flex-1 min-w-0">
        <h3 className="font-bold text-white text-xl mb-1 truncate">
          {liveAuctions.length === 1
            ? liveAuctions[0].name
            : `${liveAuctions.length} Auctions`}
        </h3>
        <div data-testid="indicator" className="flex items-center">
          <span className="flex h-3 w-3 relative mr-1">
            <span className="animate-live-ping absolute inline-flex h-3 w-3 rounded-full bg-gray-700 opacity-75"></span>
            <span className="animate-live-dot relative inline-flex rounded-full h-3 w-3 bg-gray-700"></span>
          </span>
          <span className="block leading-4 text-clhbid-orange font-medium">
            LIVE!
          </span>
        </div>
      </div>
      <Button
        href={
          liveAuctions.length === 1
            ? liveAuctions[0].parcelsUrl
            : "/#current-auctions"
        }
        className="leading-tight text-center"
        size="small"
        external
      >
        {liveAuctions.length === 1 ? "View Auction" : "View Auctions"}
      </Button>
    </div>
  );
};

export default AuctionBanner;
