import { useContext, useEffect } from "react";
import { useActor } from "@xstate/react";
import { toast } from "react-toastify";
import { AuthUser } from "../typings";

import { AuthContext } from "../providers/AuthProvider";
import { AuthMachineState } from "../machines/authMachine";

const TOAST_ID = "auth-error-toast";

/**
 * The shape of the object returned when you invoke the `useAuth()` hook.
 *
 * Use this to access the currently logged in user, and to dispatch actions
 * like `login()`, `logout()`, and `register()`.
 */
interface useAuthHookReturnType {
  login: () => void;
  logout: () => void;
  register: () => void;
  editAccount: () => void;
  /**
   * The authenticated user, if any.
   *
   * If the user is not authenticated, then this is null.
   */
  user: AuthUser | null;
  state: AuthMachineState["value"];
}

/**
 * An auth hook that can be used to retrieve the details of the authenticated
 * user, if there is one, and to log in and out.
 *
 * To use this hook, you need to include the `<AuthProvider>` above
 * any calls to this hook in your tree. Typically, you'll want to include
 * `<AuthProvider>` in your `<Layout>` or `<App>` component.
 */
export const useAuth = (): useAuthHookReturnType => {
  const authService = useContext(AuthContext);
  const [state] = useActor(authService);

  useEffect(() => {
    if (state.matches("error")) {
      toast("There was an error logging into your account. Please try again.", {
        toastId: TOAST_ID,
        autoClose: false,
        type: "error",
      });
    }
  });

  const login = () => {
    authService.send("AUTHENTICATE");
  };

  const logout = () => {
    authService.send("UNAUTHENTICATE");
  };

  const register = () => {
    authService.send("REGISTER");
  };

  const editAccount = () => {
    authService.send("EDIT_ACCOUNT");
  };

  const user = state.context.user;
  const stateValue = state.matches("authenticated")
    ? "authenticated"
    : state.value;

  return {
    state: stateValue,
    login,
    logout,
    register,
    editAccount,
    user,
  };
};
