import React, { useRef, useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { MdSearch, MdClose } from "react-icons/md";
import { createInsightsMiddleware } from "instantsearch.js/cjs/middlewares";
import { useSearchBox, useInstantSearch } from "react-instantsearch-hooks-web";
import { useLocation } from "@reach/router";

import useRouteChange from "../../../../hooks/use-route-change";

type Inputs = {
  search: string;
};

const SearchBar: React.FC<{ open: boolean; setOpen: (arg1: boolean) => void }> =
  ({ open, setOpen }) => {
    const location = useLocation();
    const { use } = useInstantSearch();
    const { query, refine } = useSearchBox();
    const form = useRef<HTMLFormElement>(null);
    const search = useRef<HTMLInputElement>(null);
    const { reset, register, handleSubmit } = useForm<Inputs>({
      reValidateMode: "onSubmit",
      defaultValues: {
        search: query,
      },
    });

    const { onChange, onBlur, name, ref } = register("search", {
      required: true,
    });

    // Sets up the analytics middleware for Algolia
    // https://www.algolia.com/doc/guides/building-search-ui/going-further/send-insights-events/react-hooks/#connecting-instantsearch-with-the-insights-middleware
    useLayoutEffect(() => {
      const middleware = createInsightsMiddleware({
        insightsClient: null,
      });

      return use(middleware);
    }, [use]);

    useRouteChange(() => {
      if (location.pathname === "/search") {
        setOpen(true);
      } else {
        setOpen(false);
        reset({ search: "" });
      }
    });

    return (
      <div className="order-first mb-8 lg:order-none lg:grow lg:mb-0 lg:justify-end">
        <form
          onSubmit={handleSubmit((data) => {
            refine(data.search);
          })}
          onBlur={({ relatedTarget }) => {
            /**
             * This handler closes the search bar when an element inside the form is blurred
             * under the conditions that the input is empty and that if an element has gained
             * focus, it is not one of the elements inside the form.
             */
            if (
              search.current.value === "" &&
              !form.current.contains(relatedTarget)
            ) {
              setOpen(false);
            }
          }}
          ref={form}
          className={[
            "[transition-property:visibility,opacity,width] w-full duration-200 relative flex items-center lg:absolute lg:top-0 lg:right-0 lg:bottom-0 lg:mr-4 xl:opacity-100",
            open
              ? "lg:visible lg:opacity-100"
              : "lg:invisible lg:opacity-0 xl:opacity-100 xl:visible xl:w-[220px]",
          ].join(" ")}
          data-testid="search-bar-form"
        >
          <input
            ref={(e) => {
              ref(e);
              search.current = e;
            }}
            onFocus={() => {
              setOpen(true);
            }}
            name={name}
            placeholder="Start your search"
            onBlur={onBlur}
            onChange={onChange}
            className={[
              "block w-full rounded-md border border-gray-300 text-lg leading-[22px] p-[10px] xl:transition-[width]",
              open ? "lg:pr-[72px]" : "lg:pr-[44px]",
            ].join(" ")}
          />
          <div className="absolute top-0 right-0 p-2">
            <button
              type="button"
              onClick={() => {
                setOpen(false);
                reset({ search: "" });
              }}
              aria-label="close search and reset input"
              className={[
                "inline-block p-1 lg:cursor-pointer hover:text-clhbid-orange lg:transition-colors",
                open ? "xl:inline-block" : "xl:hidden",
              ].join(" ")}
            >
              <MdClose className="w-5 h-5" />
            </button>
            <button
              type="submit"
              aria-label="submit search"
              className="inline-block p-1 lg:cursor-pointer hover:text-clhbid-orange lg:transition-colors"
            >
              <MdSearch className="w-5 h-5" />
            </button>
          </div>
        </form>
        <button
          onClick={() => {
            setOpen(true);
            setTimeout(() => search.current.focus(), 300);
          }}
          type="button"
          aria-label={`${open ? "Close" : "Open"} search bar`}
          className={[
            "hidden lg:block lg:p-[12px] lg:justify-end lg:ml-auto lg:mr-4 lg:cursor-pointer hover:text-clhbid-orange lg:transition-colors",
            open ? "lg:invisible" : "lg:visible",
            "xl:hidden",
          ].join(" ")}
        >
          <MdSearch className="w-5 h-5" />
        </button>
      </div>
    );
  };

export default SearchBar;
