import React from "react";
import { ToastContainer } from "react-toastify";
import { CookieAuth, DevAuth } from "../concerns/auth/implementations";
import { AuthProvider } from "../concerns/auth";

import config from "../../clhbid-config";

import Header from "./Header";
import Footer from "./Footer";
import WavesEffect from "./WavesEffect";
import CookieConsent from "./CookieConsent";
import AuctionBanner from "./AuctionBanner";
import SearchContext from "./SearchContext";

const authImpl = config.MOCK_AUTHENTICATION ? DevAuth : CookieAuth;

const Layout: React.FC<{ element: React.ReactNode }> = ({ element }) => (
  <AuthProvider impl={authImpl}>
    <SearchContext>
      <div className="font-sans text-gray-900 bg-gray-200">
        <CookieConsent />
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          position="bottom-left"
        />
        <WavesEffect />
        <Header />
        <AuctionBanner />
        <div className="flex flex-col max-w-screen-xxl mx-auto min-h-screen bg-white shadow-outline">
          <main className="flex-1 w-full">{element}</main>
          <Footer />
        </div>
      </div>
    </SearchContext>
  </AuthProvider>
);

export default Layout;
