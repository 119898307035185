import "./src/css/style.css";
import type { GatsbyBrowser } from "gatsby";

export { default as wrapPageElement } from "./src/components/Layout";

export const registerServiceWorker: GatsbyBrowser["registerServiceWorker"] =
  () => {
    return true;
  };

export const onServiceWorkerUpdateReady: GatsbyBrowser["onServiceWorkerUpdateReady"] =
  () => {
    window.location.reload();
  };
