import React from "react";
import Consent from "react-cookie-consent";

const CookieConsent: React.FC = () => (
  <Consent
    disableStyles
    location="bottom"
    buttonText="I Understand"
    containerClasses="cookie-consent"
    contentClasses="cookie-consent-content"
    overlayClasses="cookie-consent-overlay"
    buttonWrapperClasses="cookie-consent-button-wrapper"
    buttonClasses="cookie-consent-button btn btn--medium btn--yellow"
  >
    We use cookies and other technologies to improve your browsing experience on
    our website, to show you personalized content, to analyze our website
    traffic, and to understand where our visitors are coming from. By browsing
    our website, you consent to our use of cookies and other tracking
    technologies.{" "}
    <a href="https://www.cookiesandyou.com" target="_blank" rel="noreferrer">
      Learn more
    </a>
  </Consent>
);

export default CookieConsent;
