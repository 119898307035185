import { IAuthImpl } from "../typings";

const SAMPLE_USER = {
  UserName: "Bob Barker",
  Email: "bob@barker.com",
  ID: "3020678e-789d-47cf-a4c2-37c1317ea2a8",
  PhoneNumber: "123-456-7890",
  Admin: true,
};

// NOTE: Increase this timeout to allow long enough to see different auth
//       states in the UX.
const OP_TIME = 0;

export const DevAuth: IAuthImpl = {
  services: {
    initialize: () => {
      console.log("load and initialize the user");
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, OP_TIME);
      });
    },
    authenticate: () => {
      console.log("Logging in...");
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(SAMPLE_USER);
        }, OP_TIME);
      });
    },
    unauthenticate: () => {
      console.log("Logging out...");
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, OP_TIME);
      });
    },
    register: () => {
      console.log("Register user");
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(SAMPLE_USER);
        }, OP_TIME);
      });
    },
    editAccount: () => {
      console.log("Edited account");

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, OP_TIME);
      });
    },
  },
  actions: {
    handleError: (ctx, evt): void => {
      console.log("HANDLE AUTH FAILURE!");
      console.log("ctx = ", ctx, " evt = ", evt);
    },
  },
};
