import React from "react";

const LoadingIndicator: React.FC<{
  color: "white" | "black";
  className?: string;
}> = ({ color, className = "" }) => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    className={`inline animate-spin -ml-1 mr-3 h-5 w-5 text-${color} ${className}`}
  >
    <circle
      r="10"
      cx="12"
      cy="12"
      strokeWidth="4"
      stroke="currentColor"
      className="opacity-25"
    ></circle>
    <path
      fill="currentColor"
      className="opacity-75"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default LoadingIndicator;
