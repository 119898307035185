import config from "./clhbid-config";

export default {
  login: (): string => `${config.AUCTION_ENGINE_URL}/login`,
  logout: (): string => `${config.AUCTION_ENGINE_URL}/logout`,
  register: (): string => `${config.AUCTION_ENGINE_URL}/register`,
  profile: (): string => `${config.AUCTION_ENGINE_URL}/profile`,
  admin_auctions: (): string => `${config.AUCTION_ENGINE_URL}/admin/auctions`,
  admin_users: (): string => `${config.AUCTION_ENGINE_URL}/admin/users`,
  auction: {
    details: ({ url }: { url: string }): string => `/auctions/${url}`,
    reminder: ({ url }: { url: string }): string => `/auctions/${url}/reminder`,
    parcels: ({ url }: { url: string }): string =>
      `${config.AUCTION_ENGINE_URL}/auctions/${url}/parcels`,
  },
};
