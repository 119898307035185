import axios from "axios";
import { useStaticQuery, graphql } from "gatsby";
import { useQuery } from "react-query";

const AUCTION_REFRESH_INTERVAL = 10000;
const AUCTIONS_SOURCE_URL = "/auctions.json";
const AUCTIONS_QUERY_KEY = "auctions";

async function GetAuctions() {
  console.log("retrieving auctions...");
  const { status, data } = await axios.get(AUCTIONS_SOURCE_URL);
  if (status === 200) {
    console.log(`Auctions retrieved!`);
    return data;
  }
  return null;
}

const staticQuery = graphql`
  query Auctions {
    past_auctions: allAuction(
      filter: { isCompleted: { eq: true } }
      sort: { fields: auctionDate, order: DESC }
    ) {
      ...Auction
    }
    active_auctions: allAuction(
      filter: { isCompleted: { eq: false } }
      sort: { fields: auctionDate, order: ASC }
    ) {
      ...Auction
    }
  }

  fragment Auction on AuctionConnection {
    nodes {
      auctionId
      detailsUrl
      reminderUrl
      parcelsUrl
      preferredUrl
      id
      description
      auctionDate
      draftMode
      hasDetails
      isComingSoon
      isCompleted
      isReminderAvailable
      isLive
      name
      province
      url
      localImage {
        childImageSharp {
          gatsbyImageData(quality: 20, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
`;

export default function useAuctions(): {
  auctions: {
    past_auctions: Queries.AuctionsQuery["past_auctions"]["nodes"];
    active_auctions: Queries.AuctionsQuery["active_auctions"]["nodes"];
  };
  isError: boolean;
  isStale: boolean;
  isFetching: boolean;
} {
  // Static data is the auctions returned at build time
  const staticData = useStaticQuery(staticQuery);

  // To allow auctions to update without a page refresh this hook polls
  // a JSON endpoint for updated auctions from a new build and returns them
  const {
    data: query_auctions,
    isError,
    isFetching,
    isStale,
  } = useQuery<Queries.AuctionsQuery>(AUCTIONS_QUERY_KEY, GetAuctions, {
    initialData: staticData,
    refetchInterval: AUCTION_REFRESH_INTERVAL,
    staleTime: AUCTION_REFRESH_INTERVAL * 6, // 60 seconds
  });

  const active_auctions = query_auctions.active_auctions.nodes;
  const past_auctions = query_auctions.past_auctions.nodes;

  return {
    auctions: {
      active_auctions,
      past_auctions,
    },
    isError,
    isStale,
    isFetching,
  };
}
