import Joi from "joi";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

import routes from "../../../../clhbid-routes";

import { AuthUser, IAuthImpl } from "../typings";

export const CLHBID_COOKIE_NAME = "AuthenticatedUser";

const retrieveUserCookie = (): string => {
  return Cookies.get(CLHBID_COOKIE_NAME);
};

const parseUserCookie = (cookie: string): AuthUser => {
  // Decode the cookie from base64
  const decodedCookie = atob(cookie);

  // Parse the cookie as JSON
  const parsedCookie = JSON.parse(decodedCookie) as AuthUser;

  return parsedCookie;
};

const schema = Joi.object({
  UserName: Joi.string().required(),
  Email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  ID: Joi.string().required(),
  PhoneNumber: Joi.string(),
  Admin: Joi.boolean().required(),
}).required();

const validateUserCookie = async (unsafeCookie: unknown): Promise<AuthUser> => {
  const validUser = await schema.validateAsync(unsafeCookie);

  return validUser as AuthUser;
};

export const CookieAuth: IAuthImpl = {
  services: {
    initialize: async () => {
      const cookie = retrieveUserCookie();

      if (cookie != undefined) {
        const unsafeUser = parseUserCookie(cookie);
        const user = await validateUserCookie(unsafeUser);

        Sentry.addBreadcrumb({
          category: "auth",
          message: "Initialized. Authenticated user " + user.Email,
          level: "info",
        });

        return user;
      }

      Sentry.addBreadcrumb({
        category: "auth",
        message: "Initialized. No authenticated user found.",
        level: "info",
      });

      return null;
    },
    authenticate: () => {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Authenticating. Redirecting to login page.",
        level: "info",
      });

      window.location.href = routes.login();

      return Promise.resolve(null);
    },
    unauthenticate: () => {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Unauthenticating. Redirecting to logout page.",
        level: "info",
      });

      window.location.href = routes.logout();

      return Promise.resolve(null);
    },
    register: () => {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Registering. Redirecting to registration page.",
        level: "info",
      });

      window.location.href = routes.register();

      return Promise.resolve(null);
    },
    editAccount: () => {
      Sentry.addBreadcrumb({
        category: "auth",
        message: "Edit Account. Redirecting to account profile page.",
        level: "info",
      });

      window.location.href = routes.profile();

      return Promise.resolve(null);
    },
  },
  actions: {
    handleError: (ctx, evt): void => {
      const err = evt.data;

      console.log("HANDLE AUTH FAILURE!");
      console.log("ctx = ", ctx, " evt = ", evt, "err = ", err);

      Sentry.addBreadcrumb({
        category: "auth",
        message: "Error occurred",
        level: "info",
      });
      Sentry.captureException(err, {
        extra: {
          ctx,
          evt,
        },
      });
    },
  },
};
