import React from "react";

const ContactInformation: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div className={className}>
    <p itemScope itemType="https://schema.org/Organization">
      <span itemProp="name" className="hidden">
        CLHbid
      </span>
      Phone:{" "}
      <a href="tel:+1-866-263-7480">
        <span itemProp="telephone">+1 (866) 263-7480</span>
      </a>
      <br />
      Email:{" "}
      <a className="text-clhbid-orange" href="mailto:info@clhbid.com">
        <span itemProp="email">info@clhbid.com</span>
      </a>
    </p>
  </div>
);

export default ContactInformation;
