import React from "react";
import { HiMail, HiPhone } from "react-icons/hi";
import {
  MdNoAccounts,
  MdOutlineAccountCircle,
  MdKeyboardArrowDown,
} from "react-icons/md";

import routes from "../../../../clhbid-routes";

import { useAuth } from "../../../concerns/auth";
import { isHoverSupported } from "../../../helpers/media-queries";

import Button from "../../Button";

const UserMenu: React.FC<{ open: boolean; setOpen: (arg1: boolean) => void }> =
  ({ open, setOpen }) => {
    const { editAccount, logout, state, user } = useAuth();

    if (state !== "authenticated") return null;

    return (
      <div
        className="lg:pl-8 lg:py-[6px] lg:border-l lg:border-gray-300"
        onMouseEnter={() => {
          if (isHoverSupported()) setOpen(true);
        }}
        onMouseLeave={() => {
          if (isHoverSupported()) setOpen(false);
        }}
        data-testid="user-menu"
      >
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className="flex h-full leading-none items-center"
          data-testid="user-menu-toggle"
        >
          <MdNoAccounts
            className={[
              "w-10 h-10 p-[5px] lg:w-8 lg:h-8",
              open ? " block lg:hidden" : " hidden lg:hidden",
            ].join(" ")}
          />
          <MdOutlineAccountCircle
            className={[
              "w-10 h-10 p-[5px] lg:w-8 lg:h-8",
              open ? "hidden lg:block" : "block lg:block",
            ].join(" ")}
          />
          <span
            className={[
              "hidden lg:inline lg:font-semibold",
              open ? "underline" : "no-underline",
            ].join(" ")}
          >
            {user?.UserName}
            <MdKeyboardArrowDown className="inline w-[14px] h-4 pb-[1px] ml-[2px]" />
          </span>
        </button>
        <div
          className={[
            "[transition-property:visibility,transform] duration-300 absolute z-[-2] top-[100%] left-0 right-0 bottom-[calc(-100vh+100%)] overflow-y-auto lg:overflow-y-visible lg:pr-4 lg:pt-10 lg:z-0 lg:transition-opacity lg:top-[calc(100%-32px)] lg:left-auto lg:right-0 lg:bottom-auto xl:right-[calc((100%-1200px)/2)] ",
            open
              ? "visible translate-x-0 lg:translate-x-0 lg:opacity-100"
              : "invisible translate-x-full lg:translate-x-0 lg:opacity-0",
          ].join(" ")}
          data-testid="user-menu-wrapper"
        >
          <div className="h-full bg-white p-4 lg:bg-white lg:rounded-md lg:shadow-[0_0_8px_0_rgba(0,0,0,0.4)]">
            <div className="flex-row mb-4">
              <h3 className="font-bold mb-2">User Menu</h3>
              <div className="flex space-x-2">
                <HiMail className="h-6 w-6 text-black" />{" "}
                <span className="ml-1" data-testid="">
                  {user?.Email}
                </span>
              </div>
              <div className="flex space-x-2">
                <HiPhone className="h-6 w-6 text-black" />{" "}
                <span className="ml-1">
                  {user?.PhoneNumber ? (
                    user.PhoneNumber
                  ) : (
                    <button
                      onClick={editAccount}
                      className="text-clhbid-orange hover:underline"
                    >
                      Add your number
                    </button>
                  )}
                </span>
              </div>
            </div>
            {user?.Admin && (
              <div className="flex-row mb-4">
                <h3 className="font-bold mb-2">Admin Menu</h3>
                <a
                  href={routes.admin_auctions()}
                  className="text-clhbid-orange hover:underline block"
                >
                  Admin Auctions
                </a>
                <a
                  href={routes.admin_users()}
                  className="text-clhbid-orange hover:underline block"
                >
                  Admin Users
                </a>
              </div>
            )}
            <Button
              size="small"
              onClick={editAccount}
              external
              family="white"
              className="block w-full leading-tight text-center mt-2"
            >
              Edit Your Account
            </Button>
            <Button
              size="small"
              onClick={logout}
              className="block w-full leading-tight text-center mt-2"
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    );
  };

export default UserMenu;
