import React from "react";
import { Link, graphql } from "gatsby";

import Button from "../../../Button";
import AuctionCard from "../../../AuctionCard";

const AuctionPanel: React.FC<{
  title: string;
  auctions: ReadonlyArray<Queries.AuctionPanelFragment>;
  button: { title: string; path: string };
  links: Array<{ title: string; path: string }>;
}> = ({ title, auctions, button, links }) => (
  <div className="grid grid-cols-1 gap-8 mb-6 lg:gap-0 lg:mb-0 lg:grid-cols-[280px,560px]">
    <div className="flex flex-col lg:px-8 lg:border-r lg:border-gray-300">
      <h3 className="hidden text-xl leading-6 font-bold mb-6 lg:block">
        {title}
      </h3>
      {links.map((link) => (
        <Link
          key={link.title}
          to={link.path}
          className="block w-fit font-[18px] leading-6 text-gray-700 mb-4 hover:underline"
        >
          {link.title}
        </Link>
      ))}
      <Button
        external
        size="small"
        href={button.path}
        className="w-full mt-4 lg:mt-auto"
      >
        {button.title}
      </Button>
    </div>
    <div className="overflow-x-auto lg:overflow-visible">
      {auctions.length > 0 ? (
        <div className="grid grid-cols-[232px,232px] gap-8 lg:px-8">
          {auctions.map((auction) => (
            <AuctionCard key={auction.id} auction={auction} />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-full lg:px-8">
          <p className="max-w-[340px] text-center mb-0">
            It looks like there aren&apos;t any upcoming auctions. Check back
            later for more information!
          </p>
        </div>
      )}
    </div>
  </div>
);

export const AuctionPanelFragment = graphql`
  fragment AuctionPanel on Auction {
    ...AuctionCard
  }
`;

export default AuctionPanel;
