import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import { HiClock, HiCurrencyDollar, HiLocationMarker } from "react-icons/hi";

import useAuctions from "../../hooks/use-auctions";

import Button from "../Button";
import LocalDate from "../LocalDate";

const codeMap = {
  NL: "Newfoundland and Labrador",
  PE: "Prince Edward Island",
  NS: "Nova Scotia",
  NB: "New Brunswick",
  QC: "Quebec",
  ON: "Ontario",
  MB: "Manitoba",
  SK: "Saskatchewan",
  AB: "Alberta",
  BC: "British Columbia",
  YT: "Yukon",
  NT: "Northwest Territories",
  NU: "Nunavut",
};

function getStartingBid(parcels) {
  const lowestBid = String(
    parcels.reduce(
      (prev, curr) => (prev <= curr.startingBid ? prev : curr.startingBid),
      parcels[0].startingBid
    )
  );

  const lowestBidWithCommas = lowestBid.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `$${lowestBidWithCommas}`;
}

function getButtonText(isComingSoon: boolean, isLive: boolean): string {
  if (isLive) return "Auction is Live";
  if (isComingSoon) return "Coming Soon";
  return "View Details";
}

const AuctionCard: React.FC<{
  auction: Queries.AuctionCardFragment;
  className?: string;
}> = ({ auction, className = "" }) => {
  const data = useStaticQuery<Queries.AuctionCardQueryQuery>(query);
  const { auctions } = useAuctions();

  const isLive = auctions.active_auctions.some(
    ({ id, isLive }) => auction.id === id && isLive
  );

  const content = (
    <>
      <div className={`relative ${className}`}>
        <GatsbyImage
          alt={`View from above of ${auction.name}`}
          image={
            (auction?.localImage || data.defaultImage).childImageSharp
              .gatsbyImageData
          }
          className="block"
        />
        {isLive && (
          <span className="absolute left-0 bottom-0 text-xs uppercase px-2 py-[2px] bg-clhbid-orange rounded-tr-md font-semibold">
            Live
          </span>
        )}
      </div>
      <div className="p-2 pb-4">
        <div className="mb-[10px]">
          <h4 className="text-lg font-bold truncate">{auction.name}</h4>
          <h5 className="text-xs text-gray-700">
            Located in {codeMap[auction.province] || auction.province}
          </h5>
        </div>
        <div className="flex items-center mb-1">
          <HiClock className="w-5 h-5 text-gray-400 mr-1" />
          <LocalDate
            className="leading-none uppercase"
            dateAsISOString={auction.auctionDate}
            formatOptions={{
              month: "short",
              day: "numeric",
            }}
          />
          <span className="text-xs lowercase mx-1">at</span>
          <LocalDate
            className="leading-none uppercase"
            dateAsISOString={auction.auctionDate}
            formatOptions={{
              hour: "numeric",
              minute: "numeric",
              timeZoneName: "short",
            }}
          />
        </div>
        <div className="flex items-center mb-1">
          <HiCurrencyDollar className="w-5 h-5 text-gray-400 mr-1" />
          <span className="leading-none">
            {getStartingBid(auction.parcels)}{" "}
            <span className="text-xs">starting bid</span>
          </span>
        </div>
        <div className="flex items-center">
          <HiLocationMarker className="w-4 h-4 text-white bg-gray-400 mr-1 p-1 rounded-full m-[2px]" />
          <span className="leading-none">
            {auction.parcels.length}
            {auction.parcels.length > 1 ? " Parcels " : " Parcel "}
            <span className="text-xs">for auction</span>
          </span>
        </div>
      </div>
      <Button
        disabled={auction.isComingSoon}
        className="block w-full text-xs px-8 py-2"
      >
        {getButtonText(auction.isComingSoon, isLive)}
      </Button>
    </>
  );

  if (auction.isComingSoon) {
    return (
      <div className="block isolate bg-white rounded-md lg:shadow-[0_0_8px_0_rgba(0,0,0,0.2)] overflow-hidden">
        {content}
      </div>
    );
  }

  if (auction.preferredUrl.startsWith("https://")) {
    return (
      <a
        tabIndex={-1}
        href={auction.preferredUrl}
        className="block isolate bg-white rounded-md lg:shadow-[0_0_8px_0_rgba(0,0,0,0.2)] overflow-hidden"
      >
        {content}
      </a>
    );
  }

  return (
    <Link
      tabIndex={-1}
      to={auction.preferredUrl}
      className="block isolate bg-white rounded-md lg:shadow-[0_0_8px_0_rgba(0,0,0,0.2)] overflow-hidden"
    >
      {content}
    </Link>
  );
};

const query = graphql`
  query AuctionCardQuery {
    defaultImage: file(relativePath: { eq: "coming-soon.png" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.6)
      }
    }
  }
`;

export const AuctionCardFragment = graphql`
  fragment AuctionCard on Auction {
    id
    auctionDate
    isComingSoon
    localImage {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.6)
      }
    }
    name
    parcels {
      startingBid
    }
    preferredUrl
    province
  }
`;

export default AuctionCard;
