import React, { useRef, useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "gatsby";

import { isHoverSupported } from "../../../../helpers/media-queries";

import { calculateHeight, calculateOffset } from "./Dropdown.helpers";

const Dropdown: React.FC<{
  title: string;
  link: string;
  open: boolean;
  setOpen: (arg1: boolean) => void;
  children: React.ReactNode;
}> = ({ title, link, open, setOpen, children }) => {
  const [height, setHeight] = useState(0);
  const container = useRef<HTMLDivElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  useEffect(() => {
    const calculateValues = () => {
      if (button.current && container.current) {
        setHeight(calculateHeight(open, container.current.scrollHeight));
        setOffset(calculateOffset(window, button.current, container.current));
      }
    };

    calculateValues();

    window.addEventListener("resize", calculateValues);

    return () => {
      window.removeEventListener("resize", calculateValues);
    };
  }, [open]);

  return (
    <div
      onMouseEnter={() => {
        if (isHoverSupported()) setOpen(true);
      }}
      onMouseLeave={() => {
        if (isHoverSupported()) setOpen(false);
      }}
      className="border-b border-gray-300 lg:border-none shrink-0"
      data-testid="dropdown"
    >
      <button
        ref={button}
        onClick={() => {
          setOpen(!open);
        }}
        className={[
          "flex w-full justify-between content-end py-4 font-semibold text-xl leading-6 lg:w-fit lg:justify-start lg:py-0 lg:text-base lg:leading-none",
          open ? "underline" : "no-underline",
        ].join(" ")}
        aria-label={`${open ? "Close" : "Open"} Dropdown`}
        data-testid="dropdown-button"
      >
        <Link to={link}>{title}</Link>
        <MdKeyboardArrowDown className="inline w-6 h-6 lg:w-[14px] lg:h-4 lg:pt-[2px]" />
      </button>
      <div
        ref={container}
        style={{
          top: `${offset.top}px`,
          left: `${offset.left}px`,
          maxHeight: `${height}px`,
        }}
        className={[
          "[transition-property:visibility,max-height] duration-300 overflow-y-hidden lg:transition-opacity lg:fixed lg:overflow-visible",
          open ? "visible lg:opacity-100" : "invisible lg:opacity-0",
        ].join(" ")}
        data-testid="dropdown-wrapper"
      >
        <div className="lg:pr-2 lg:pt-[54px]">
          <div className="lg:bg-white lg:rounded-lg lg:shadow-[0_0_8px_0_rgba(0,0,0,0.4)] lg:py-8">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
