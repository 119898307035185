/**
 * CLHBid Config
 *
 * The purpose of this file is to allow us to create robust configuration objects in
 * a single place based upon the environment variables. Consult the .env.sample file
 * for information regarding what environment variables need to be set depending on
 * the environment the code is running in.
 *
 * Note: Environment variables that begin with the GATSBY_ prefix are available on both
 * the server and the client, while those without are only available server-side.
 */

function parse(value) {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
}

const config = {
  /** The key used to publish search data to Algolia */
  ALGOLIA_ADMIN_KEY: process.env.ALGOLIA_ADMIN_KEY,
  /** The ID of the Algolia app to publish and retrieve data from */
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID,
  /** The key used to retrieve search results from Algolia client-side */
  ALGOLIA_SEARCH_KEY: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  /** The token used to authenticate requests to the API */
  API_AUTH_TOKEN: process.env.API_AUTH_TOKEN,
  /** The interval in milliseconds that must pass before the request limit is reset */
  API_REQUEST_INTERVAL: parseInt(process.env.API_REQUEST_INTERVAL) || 1000,
  /** The number of requests per second that can be made to the auction engine */
  API_REQUEST_LIMIT: parseInt(process.env.API_REQUEST_LIMIT) || 50,
  /** The base url to use when referencing the auction engine */
  AUCTION_ENGINE_URL: process.env.GATSBY_BASE_AUCTION_ENGINE_URL,
  /** Determines the environment the application runs in */
  GATSBY_ENV: process.env.GATSBY_ENV,
  /** The ID of the container for google analytics 4 */
  GOOGLE_ANALYTICS_4_ID: process.env.GOOGLE_ANALYTICS_4_ID,
  /** The key used to validate to the Mailchimp servers */
  MAILCHIMP_API_KEY: process.env.MAILCHIMP_API_KEY,
  /** The key of the interest category to add auction reminders to (groups in UI) */
  MAILCHIMP_CATEGORY_ID: process.env.MAILCHIMP_CATEGORY_ID,
  /** The ID of the list to add new contacts to */
  MAILCHIMP_LIST_ID: process.env.MAILCHIMP_LIST_ID,
  /** The ID of the Mailchimp server to connect to */
  MAILCHIMP_SERVER: process.env.MAILCHIMP_SERVER,
  /** Indicates whether to load a snapshot of mock data instead of the real data */
  MOCK_AUCTION_DATA: parse(process.env.MOCK_AUCTION_DATA),
  /** Indicates whether to mock the authentication layer */
  MOCK_AUTHENTICATION: parse(process.env.GATSBY_MOCK_AUTHENTICATION),
  /** The ID of the hotjar account */
  HOTJAR_ID: process.env.HOTJAR_ID,
  /** The version of the Hotjar snippet to install */
  HOTJAR_SNIPPET_VERSION: process.env.HOTJAR_SNIPPET_VERSION,
  /** The API key that allows us to send analytics data to Posthog */
  POSTHOG_KEY: process.env.POSTHOG_KEY,
  /** The production URL, used for building the sitemap, robots, metadata, etc. */
  PRODUCTION_SITE_URL: "https://clhbid.com",
  /** The url that we want sentry to send all error reporting to */
  SENTRY_DSN: process.env.SENTRY_DSN,
  /** The auth token used to authenticate to Twilio for backend requests */
  TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN,
  /** The account SID used to authenticate to Twilio for backend requests */
  TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
  /** The phone number to send Twilio messages from */
  TWILIO_PHONE_NUMBER: process.env.TWILIO_PHONE_NUMBER,
};

// This object defines the options used to validate whether the exported config is valid
export const options = {
  environments: ["development", "staging", "production"],
  exclude: {
    development: [
      "GOOGLE_ANALYTICS_4_ID",
      "HOTJAR_ID",
      "HOTJAR_SNIPPET_VERSION",
      "POSTHOG_KEY",
      "SENTRY_DSN",
    ],
    staging: [
      "GOOGLE_ANALYTICS_4_ID",
      "HOTJAR_ID",
      "HOTJAR_SNIPPET_VERSION",
      "POSTHOG_KEY",
      "SENTRY_DSN",
    ],
    production: ["MOCK_AUCTION_DATA", "MOCK_AUTHENTICATION"],
  },
};

export default config;
