import React from "react";
import { Link } from "gatsby";

import routes from "../../clhbid-routes";

import SocialIcons from "./SocialIcons";
import ContactInformation from "./ContactInformation";

import logo from "../images/clhbid-logo.png";

const menus = [
  {
    title: `Why CLHbid.com`,
    path: `/why-clhbid/`,
    links: [
      {
        title: "Private Sale",
        path: "/why-clhbid/clhbid-vs-private-sale/",
      },
      {
        title: "Professional Realtors",
        path: "/why-clhbid/clhbid-vs-listing-with-professional-realtors/",
      },
      {
        title: "Public Auction",
        path: "/why-clhbid/clhbid-vs-public-auction/",
      },
      {
        title: "Private Tender",
        path: "/why-clhbid/clhbid-vs-private-tender/",
      },
      {
        title: "Testimonials",
        path: "/why-clhbid/clhbid-testimonials/",
      },
    ],
  },
  {
    title: `How We Work`,
    path: `/how-clhbid-works/`,
    links: [
      {
        title: `Bidding on Properties`,
        path: `/how-clhbid-works/bidding-on-properties-on-clhbid/`,
      },
      {
        title: `Starting Bids`,
        path: `/how-clhbid-works/starting-bids-on-the-clhbid-auction-platform/`,
      },
      {
        title: `En Bloc Process`,
        path: `/how-clhbid-works/bidding-on-en-bloc-properties-on-clhbid/`,
      },
      {
        title: `Closing the Sale`,
        path: `/how-clhbid-works/closing-the-sale-post-auction/`,
      },
      {
        title: `Transaction Fee`,
        path: `/how-clhbid-works/clhbid-transaction-fee/`,
      },
      {
        title: `Terms and Conditions`,
        path: `/how-clhbid-works/clhbid-bidding-terms-and-conditions/`,
      },
    ],
  },
  {
    title: "Account",
    links: [
      {
        title: `Login`,
        path: routes.login(),
      },
      {
        title: `Create an Account`,
        path: routes.register(),
      },
      {
        title: `Contact Us`,
        path: `/contact/`,
      },
    ],
  },
];

const Footer: React.FC = () => (
  <footer className="bg-gray-100 w-full flex flex-col align-center">
    <div className="w-full flex justify-center">
      <nav className="flex-grow flex flex-col md:flex-row justify-between max-w-screen-xl w-screen-xl px-3 py-4 text-lg md:py-6">
        <div className="mb-10">
          <img src={logo} alt="CLHbid.com logo" className="w-48 mb-6" />
          <ContactInformation />
          <SocialIcons />
        </div>
        {menus.map((menu) => (
          <section key={menu.title} className="mb-6">
            <h3 className="text-2xl mb-3 md:mb-6">
              {menu.path ? (
                <Link to={menu.path}>{menu.title}</Link>
              ) : (
                menu.title
              )}
            </h3>
            {menu.links.map((link) => {
              const className =
                "block text-clhbid-orange mb-2 hover:text-clhbid-orange-dark transition-colors duration-300";

              return link.path.startsWith("https://") ? (
                <a key={link.title} href={link.path} className={className}>
                  {link.title}
                </a>
              ) : (
                <Link key={link.title} to={link.path} className={className}>
                  {link.title}
                </Link>
              );
            })}
          </section>
        ))}
      </nav>
    </div>
    <div className="text-center m-2 md:m-8">
      © Copyright CLHbid.com | All Rights Reserved.
    </div>
  </footer>
);

export default Footer;
