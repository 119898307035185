import React, { useState, useEffect, useRef } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { MdMenu, MdMenuOpen } from "react-icons/md";

import routes from "../../../../clhbid-routes";

import { useAuth } from "../../../concerns/auth";
import useAuctions from "../../../hooks/use-auctions";
import useRouteChange from "../../../hooks/use-route-change";
import { isMobileDevice } from "../../../helpers/media-queries";

import Button from "../../Button";

import Dropdown from "./Dropdown";
import SearchBar from "./SearchBar";
import { AuctionPanel, TestimonialPanel, LinkPanel } from "./Panels";

const MainMenu: React.FC<{
  open: boolean;
  setOpen: (arg1: boolean) => void;
}> = ({ open, setOpen }) => {
  const { auctions } = useAuctions();
  const wrapper = useRef<HTMLDivElement>(null);
  const { state, register, login } = useAuth();
  const data = useStaticQuery<Queries.MainMenuQuery>(query);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<Array<string>>([]);

  /**
   * This function handles the case where dropdowns are open on tablet
   * and the user clicks elsewhere by stopping the event from registering
   * and closing the menu
   */
  const closeDropdown = (event) => {
    if (openDropdown.length > 0 && !wrapper.current?.contains(event.target)) {
      event.preventDefault();
      setOpenDropdown([]);
    }
  };

  /*
   * Returns a function that simplifies the opening and closing of dropdowns
   * by abstracting the fact that `openDropdown` is a complex state
   */
  const createSetOpenDropdown = (dropdown: string) => {
    return (value: boolean) => {
      // On deskton and tablet we want only a single dropdown to be open
      // while on mobile many dropdowns can be opened
      const isMobile = isMobileDevice();

      // We only handle the two case where state needs to be changed. If
      // value === true and it is already opened or if value === false and
      // it is already closed do nothing
      if (value === true && !openDropdown.includes(dropdown)) {
        setOpenDropdown((state) =>
          isMobile ? [...state, dropdown] : [dropdown]
        );
      } else if (value === false && openDropdown.includes(dropdown)) {
        setOpenDropdown((state) =>
          isMobile ? state.filter((string) => string !== dropdown) : []
        );
      }
    };
  };

  useEffect(() => {
    window.addEventListener("click", closeDropdown);

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  });

  useRouteChange(() => {
    setOpenDropdown([]);
  });

  return (
    <div className="order-1 lg:order-none lg:flex lg:align-end">
      <button
        className="block lg:hidden"
        onClick={() => setOpen(!open)}
        aria-label={`${open ? "Open" : "Close"} main menu`}
        data-testid="main-menu-toggle"
      >
        {open ? (
          <MdMenuOpen className="w-10 h-10 p-1 rotate-180" />
        ) : (
          <MdMenu className="w-10 h-10 p-1" />
        )}
      </button>
      <div
        ref={wrapper}
        className={[
          "[transition-property:visibility,transform] duration-300 absolute z-[-2] w-full p-4 bg-white overflow-y-auto top-[100%] left-0 right-0 bottom-[calc(-100vh+100%)] lg:flex lg:relative lg:z-auto lg:inset-0 lg:p-0 lg:justify-between lg:overflow-y-visible",
          open
            ? "visible translate-x-none lg:visible lg:transform-none"
            : "invisible translate-x-full lg:visible lg:transform-none",
        ].join(" ")}
        data-testid="main-menu-wrapper"
      >
        {auctions.active_auctions.some((auction) => auction.isLive) && (
          <div
            className={[
              "hidden lg:block lg:absolute lg:left-1 lg:-top-1 lg:px-2 lg:bg-clhbid-orange lg:rounded-sm lg:skew-x-[-30deg]",
              openSearch ? "lg:invisible" : "lg:visible",
            ].join(" ")}
          >
            <span className="block text-xs uppercase font-semibold skew-x-[30deg]">
              Live
            </span>
          </div>
        )}
        <div
          className={[
            "flex flex-col lg:relative lg:flex-row lg:items-center lg:grow lg:gap-8 lg:py-[14px] xl:pr-[252px]",
            openSearch ? "lg:invisible" : "lg:visible",
          ].join(" ")}
        >
          <Dropdown
            title="Buy Land"
            link="/#current-auctions"
            open={openDropdown.includes("buy-land")}
            setOpen={createSetOpenDropdown("buy-land")}
          >
            <AuctionPanel
              title="Buy Land"
              auctions={data.auctions.nodes}
              links={[
                {
                  path: "/#current-auctions",
                  title: "Upcoming Auctions",
                },
                {
                  path: "/#past-auctions",
                  title: "Completed Auctions",
                },
                {
                  path: "/how-clhbid-works/bidding-on-properties-on-clhbid",
                  title: "The Auction Process",
                },
                {
                  path: "/how-clhbid-works/clhbid-bidding-terms-and-conditions",
                  title: "Terms and Conditions",
                },
              ]}
              button={{
                path: routes.register(),
                title: "Register To Bid",
              }}
            />
          </Dropdown>
          <Dropdown
            title="Sell Land"
            link="/sell-with-clhbid"
            open={openDropdown.includes("sell-land")}
            setOpen={createSetOpenDropdown("sell-land")}
          >
            <TestimonialPanel
              title="Sell Land"
              testimonials={data.testimonials.nodes}
              links={[
                {
                  path: "/sell-with-clhbid",
                  title: "Sell With CLHbid.com",
                },
                {
                  path: "/about-clhbid/clhbid-team",
                  title: "The CLHbid.com Team",
                },
                {
                  path: "/why-clhbid/clhbid-testimonials",
                  title: "Customer Testimonials",
                },
                {
                  path: "/about-clhbid/frequently-asked-questions",
                  title: "Frequently Asked Questions",
                },
              ]}
              button={{
                path: "/contact",
                title: "Contact Us Today",
              }}
            />
          </Dropdown>
          <Dropdown
            title="More"
            link="/about-clhbid"
            open={openDropdown.includes("more")}
            setOpen={createSetOpenDropdown("more")}
          >
            <LinkPanel
              title="More"
              sections={[
                {
                  title: "Why CLHbid.com",
                  links: [
                    {
                      path: "/about-clhbid/clhbid-team",
                      title: "The CLHbid.com Team",
                    },
                    {
                      path: "/why-clhbid/clhbid-testimonials",
                      title: "Customer Testimonials",
                    },
                    {
                      path: "/how-clhbid-works/clhbid-bidding-terms-and-conditions",
                      title: "Terms and Conditions",
                    },
                    {
                      path: "/about-clhbid/frequently-asked-questions",
                      title: "Frequently Asked Questions",
                    },
                  ],
                },
                {
                  title: "How It Works",
                  links: [
                    {
                      path: "/how-clhbid-works/bidding-on-properties-on-clhbid",
                      title: "Bidding on Properties",
                    },
                    {
                      path: "/how-clhbid-works/starting-bids-on-the-clhbid-auction-platform",
                      title: "Starting Bids",
                    },
                    {
                      path: "/how-clhbid-works/bidding-on-en-bloc-properties-on-clhbid",
                      title: "En Bloc Process",
                    },
                    {
                      path: "/how-clhbid-works/closing-the-sale-post-auction",
                      title: "Closing the Sale",
                    },
                    {
                      path: "/how-clhbid-works/clhbid-transaction-fee",
                      title: "Transaction Fee",
                    },
                  ],
                },
                {
                  title: "Compare Alternatives",
                  links: [
                    {
                      path: "/why-clhbid/clhbid-vs-private-sale",
                      title: "Private Sale",
                    },
                    {
                      path: "/why-clhbid/clhbid-vs-public-auction",
                      title: "Public Auction",
                    },
                    {
                      path: "/why-clhbid/clhbid-vs-private-tender",
                      title: "Private Tender",
                    },
                    {
                      path: "/why-clhbid/clhbid-vs-listing-with-professional-realtors",
                      title: "Professional Realtor",
                    },
                  ],
                },
              ]}
            />
          </Dropdown>
          <Link
            to="/contact"
            className="block w-full text-xl leading-6 font-semibold py-4 border-b border-gray-300 hover:underline lg:w-fit lg:text-base lg:leading-none lg:py-0 lg:border-none"
          >
            Contact
          </Link>
          <SearchBar open={openSearch} setOpen={setOpenSearch} />
        </div>
        {state !== "authenticated" && (
          <div className="lg:flex lg:items-center gap-6 lg:border-l lg:border-gray-300">
            <button
              onClick={login}
              className="block w-full text-xl leading-6 font-semibold py-4 text-left hover:underline lg:w-fit lg:text-base lg:leading-none lg:py-0 lg:ml-8"
            >
              Login
            </button>
            <Button
              size="small"
              onClick={register}
              className="w-full whitespace-nowrap mt-8 lg:mt-0"
              data-testid="register-button"
            >
              Create Account
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const query = graphql`
  query MainMenu {
    auctions: allAuction(
      limit: 2
      sort: { fields: auctionDate, order: ASC }
      filter: {
        draftMode: { eq: false }
        isComingSoon: { eq: false }
        isCompleted: { eq: false }
      }
    ) {
      nodes {
        ...AuctionPanel
      }
    }
    testimonials: allTestimonialsYaml(filter: { priority: { eq: true } }) {
      nodes {
        ...TestimonialPanel
      }
    }
  }
`;

export default MainMenu;
