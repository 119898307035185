module.exports = {
  /**
    To avoid infinite looping between watch tools we manually target the
    folders we want tailwind to check for classnames
  */
  content: [
    "./src/pages/**/*.{js,jsx,tx,tsx,mdx}",
    "./src/sections/**/*.{js,jsx,tx,tsx,mdx}",
    "./src/components/**/*.{js,jsx,tx,tsx,mdx}",
    "./src/templates/**/*.{js,jsx,tx,tsx,mdx}",
  ],
  theme: {
    extend: {
      boxShadow: {
        outline: "0 0 1em rgba(0, 0, 0, 0.1)",
        button:
          "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        buttonFocus:
          "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12), 0px 0px 10px 5px rgba(248, 179, 50, 0.5)",
      },
      colors: {
        "clhbid-orange": "#f8b332",
        "clhbid-orange-dark": "#E39607",
        "black-50": "rgba(0,0,0,0.5)",
        gray: {
          100: "#f5f5f5",
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#bdbdbd",
          500: "#9e9e9e",
          600: "#757575",
          700: "#616161",
          800: "#424242",
          900: "#212121",
        },
      },
      screens: {
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        "2xl": "1536px",
      },
      maxWidth: {
        xxs: "16rem",
        "1/4": "25%",
        "w-screen-xxl": "1680px",
      },
      minWidth: {
        64: "16rem",
        80: "20rem",
        96: "24rem",
        128: "32rem",
      },
      width: {
        "w-screen-xxl": "1680px",
      },
      minHeight: {
        64: "16rem",
        80: "20rem",
        96: "24rem",
        128: "32rem",
      },
      padding: {
        // Per https://css-tricks.com/fluid-width-video/
        "1080p": "56.25%",
      },
      spacing: {
        80: "20rem",
        144: "36rem",
      },
      keyframes: {
        "live-ping": {
          "75%, 100%": {
            opacity: "0",
            transform: "scale(1.6)",
            backgroundColor: "#F8B332",
          },
        },
        "live-dot": {
          "75%, 100%": {
            backgroundColor: "#F8B332",
          },
        },
      },
      animation: {
        "live-ping":
          "live-ping 0.5s cubic-bezier(0, 0, 0.5, 1) infinite alternate",
        "live-dot":
          "live-dot 0.5s cubic-bezier(0, 0, 0.5, 1) infinite alternate",
      },
      backgroundImage: {
        "image-fade":
          "linear-gradient(to right, rgba(255,255,255,.9) 0%, rgba(255,255,255,.9) 46%, rgba(255,255,255,0) 100%)",
      },
    },
  },
  // https://github.com/tailwindlabs/tailwindcss-forms
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/line-clamp"),
    require("tailwindcss-interaction-variants"),
  ],
};
